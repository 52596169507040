import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SortOrder } from 'src/utils/constants';
import type { RootState } from 'src/store/index';

export interface EntryFilterState {
  year: number;
  page: number;
  pageSize: number;
  order: SortOrder;
  orderBy: string;
}

const initialState: EntryFilterState = {
  year: 0,
  page: 0,
  pageSize: 10,
  order: 'asc',
  orderBy: 'name',
};

export const entryFilterSlice = createSlice({
  name: 'entryFilter',
  initialState,
  reducers: {
    setYear: (state, action: PayloadAction<number>) => {
      state.year = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setOrder: (state, action: PayloadAction<SortOrder>) => {
      state.order = action.payload;
    },
    setOrderBy: (state, action: PayloadAction<string>) => {
      state.orderBy = action.payload;
    },
  },
});

export const { setYear, setPage, setOrder, setOrderBy } = entryFilterSlice.actions;

export default entryFilterSlice.reducer;

export const entryFilterStateSelector = (state: RootState): EntryFilterState => state.entryFilter;
