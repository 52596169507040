import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AuthService, ForgotPasswordRequest } from 'src/api';
import type { AppThunk } from 'src/store/index';

import { fetchStart, fetchSuccess, fetchError } from 'src/store/slices/commonSlice';

export interface AuthState {
  loading: boolean;
  hasError: boolean;
  error?: string;
}

const initialState: AuthState = { loading: false, hasError: false, error: '' };

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = `${action.payload}`;
    },
  },
});

export const { hasError } = authSlice.actions;

export const onForgotPassword =
  (email: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchStart());
      await AuthService.postApiAuthForgotPassword({
        email,
      } as ForgotPasswordRequest);

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError((e as Error).message));
    }
  };

export const onResetPassword =
  (email: string, password: string, token: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchStart());
      await AuthService.postApiAuthResetPassword({ email, password, token });

      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError((e as Error).message));
    }
  };

export default authSlice.reducer;
