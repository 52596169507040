import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/dashboard/app'));

// USER
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));

// EVENT
const EventListPage = lazy(() => import('src/pages/dashboard/event/list'));
const EventCreatePage = lazy(() => import('src/pages/dashboard/event/new'));
const EventEditPage = lazy(() => import('src/pages/dashboard/event/edit'));

// CLUB
const ClubListPage = lazy(() => import('src/pages/dashboard/club/list'));
const ClubCreatePage = lazy(() => import('src/pages/dashboard/club/new'));
const ClubEditPage = lazy(() => import('src/pages/dashboard/club/edit'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      {
        path: 'user',
        children: [
          { element: <UserListPage />, index: true },
          { path: 'list', element: <UserListPage /> },
          { path: ':id/edit', element: <UserEditPage /> },
          { path: ':id/account', element: <UserAccountPage /> },
        ],
      },
      {
        path: 'event',
        children: [
          { element: <EventListPage />, index: true },
          { path: 'list', element: <EventListPage /> },
          { path: 'new', element: <EventCreatePage /> },
          { path: ':id/edit', element: <EventEditPage /> },
        ],
      },
      {
        path: 'club',
        children: [
          { element: <ClubListPage />, index: true },
          { path: 'list', element: <ClubListPage /> },
          { path: 'new', element: <ClubCreatePage /> },
          { path: ':id/edit', element: <ClubEditPage /> },
        ],
      },
    ],
  },
];
