/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClubDto } from '../models/ClubDto';
import type { UserDto } from '../models/UserDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersService {

    /**
     * @returns UserDto Success
     * @throws ApiError
     */
    public static getApiUsers(): CancelablePromise<Array<UserDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users',
        });
    }

    /**
     * @returns UserDto Success
     * @throws ApiError
     */
    public static getApiUsersMy(): CancelablePromise<UserDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users/my',
        });
    }

    /**
     * @param id 
     * @returns UserDto Success
     * @throws ApiError
     */
    public static getApiUsers1(
id: number,
): CancelablePromise<UserDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns UserDto Success
     * @throws ApiError
     */
    public static putApiUsers(
id: number,
requestBody?: UserDto,
): CancelablePromise<UserDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/users/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns UserDto Success
     * @throws ApiError
     */
    public static deleteApiUsers(
id: number,
): CancelablePromise<UserDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/users/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param clubId 
     * @returns ClubDto Success
     * @throws ApiError
     */
    public static postApiUsersAddclub(
id: number,
clubId?: number,
): CancelablePromise<ClubDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/{id}/addclub',
            path: {
                'id': id,
            },
            query: {
                'clubId': clubId,
            },
        });
    }

    /**
     * @param id 
     * @param clubId 
     * @returns boolean Success
     * @throws ApiError
     */
    public static deleteApiUsersRemoveclub(
id: number,
clubId?: number,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/users/{id}/removeclub',
            path: {
                'id': id,
            },
            query: {
                'clubId': clubId,
            },
        });
    }

}
