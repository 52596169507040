import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EntryCompetitorDto, EventPriceDrawDto, EventService } from 'src/api';
import type { AppThunk, RootState } from 'src/store/index';
import { fetchStart, fetchSuccess, fetchError } from 'src/store/slices/commonSlice';

export interface EventPriceState {
  entries: EntryCompetitorDto[] | null;
  priceDraws: EventPriceDrawDto[] | null;
}

const initialState: EventPriceState = {
  entries: [],
  priceDraws: [],
};

export const eventPriceSlice = createSlice({
  name: 'eventPrice',
  initialState,
  reducers: {
    updateEntries: (state, action: PayloadAction<EntryCompetitorDto[]>) => {
      state.entries = action.payload;
    },
    updatePriceDraws: (state, action: PayloadAction<EventPriceDrawDto[]>) => {
      state.priceDraws = action.payload;
    },
    addPriceDraw: (state, action: PayloadAction<EventPriceDrawDto>) => {
      state.priceDraws!.push(action.payload);
    },
  },
});

export const { updateEntries, updatePriceDraws, addPriceDraw } = eventPriceSlice.actions;

export const onGetEventPriceDraws =
  (id: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchStart());
      const priceDraws = await EventService.getApiEventsPricedraws(id);

      dispatch(updatePriceDraws(priceDraws));
      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError((e as Error).message));
    }
  };

export const onSaveEventPriceDraws =
  (priceDraw: EventPriceDrawDto): AppThunk =>
  async (dispatch) => {
    try {
      const newPriceDraw = await EventService.postApiEventsPricedraw(priceDraw);
      dispatch(addPriceDraw(newPriceDraw));
    } catch (e) {
      dispatch(fetchError((e as Error).message));
    }
  };

export const onGetEventEntries =
  (id: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchStart());
      const entries = await EventService.getApiEventsEntries(id);

      dispatch(updateEntries(entries));
      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError((e as Error).message));
    }
  };

export default eventPriceSlice.reducer;

export const eventPriceStateSelector = (state: RootState): EventPriceState => state.eventPrice;
