import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
  createListenerMiddleware,
} from '@reduxjs/toolkit';

import accountFormReducer from './slices/accountFormSlice';
import authReducer from './slices/authSlice';
import regionReducer from './slices/regionSlice';
import commonReducer from './slices/commonSlice';
import clubReducer from './slices/clubSlice';
import eventReducer from './slices/eventSlice';
import eventPriceReducer from './slices/eventPriceSlice';
import clubEventReducer from './slices/clubEventSlice';
import clubEventFilterReducer from './slices/clubEventFilterSlice';
import userReducer from './slices/userSlice';
import settingReducer from './slices/settingSlice';
import municipalityReducer from './slices/municipalitySlice';
import eventFilterReducer from './slices/eventFilterSlice';
import entryFilterReducer from './slices/entryFilterSlice';
import entryReducer from './slices/entrySlice';
import eventNewsReducer from './slices/eventNewsSlice';
import menuReducer from './slices/menuSlice';
import statisticsReducer from './slices/statisticsSlice';

const combinedReducer = combineReducers({
  accountForm: accountFormReducer,
  auth: authReducer,
  region: regionReducer,
  common: commonReducer,
  club: clubReducer,
  clubEvent: clubEventReducer,
  clubEventFilter: clubEventFilterReducer,
  event: eventReducer,
  eventPrice: eventPriceReducer,
  eventFilter: eventFilterReducer,
  user: userReducer,
  setting: settingReducer,
  municipality: municipalityReducer,
  entryFilter: entryFilterReducer,
  entry: entryReducer,
  eventNews: eventNewsReducer,
  menu: menuReducer,
  statistics: statisticsReducer,
});

const listenerMiddleware = createListenerMiddleware();

export const store = configureStore({
  reducer: combinedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(listenerMiddleware.middleware),
  devTools: process.env.NODE_ENV === 'development',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, any, Action<string>>;

const createBrowserHistory = require('history').createBrowserHistory;

const history = createBrowserHistory();
export { history };
