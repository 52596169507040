import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from 'src/store/index';
import { fetchStart, fetchSuccess, fetchError, showMessage } from 'src/store/slices/commonSlice';
import { EventNewsDto, EventNewsService } from 'src/api';

export interface EventNewsState {
  eventNews: EventNewsDto[] | null;
  selectedEventNews: EventNewsDto | null;
}

const initialState: EventNewsState = {
  eventNews: [],
  selectedEventNews: null,
};

export const eventNewsSlice = createSlice({
  name: 'eventNews',
  initialState,
  reducers: {
    setList: (state, action: PayloadAction<EventNewsDto[]>) => {
      state.eventNews = action.payload;
    },
    addNews: (state, action: PayloadAction<EventNewsDto>) => {
      state.eventNews!.push(action.payload);
    },
    updateNews: (state, action: PayloadAction<EventNewsDto>) => {
      state.selectedEventNews = action.payload;
      state.eventNews = state.eventNews!.map((eventNews) =>
        eventNews.id === action.payload.id ? action.payload : eventNews
      );
    },
    setSelectedEventNews: (state, action: PayloadAction<EventNewsDto>) => {
      state.selectedEventNews = action.payload;
    },
  },
});

export const { setList, addNews, updateNews, setSelectedEventNews } = eventNewsSlice.actions;

export const onGetEventNews =
  (eventId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchStart());
      const eventNews = await EventNewsService.getApiEventnews(eventId);

      dispatch(setList(eventNews));
      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError((e as Error).message));
    }
  };

export const onCreateEventNews =
  (eventNews: EventNewsDto): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchStart());
      const result = await EventNewsService.postApiEventnews(eventNews);

      dispatch(fetchSuccess());
      dispatch(addNews(result));
      dispatch(showMessage('message.eventNewsCreated'));
    } catch (e) {
      dispatch(fetchError((e as Error).message));
    }
  };

export const onUpdateSelectedEventNews =
  (eventNews: EventNewsDto): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchStart());
      const result = await EventNewsService.putApiEventnews(eventNews.id!, eventNews);

      dispatch(fetchSuccess());
      dispatch(updateNews(result));
      dispatch(showMessage('message.eventNewsUpdated'));
    } catch (e) {
      dispatch(fetchError((e as Error).message));
    }
  };

export default eventNewsSlice.reducer;

export const eventNewsStateSelector = (state: RootState): EventNewsState => state.eventNews;
