/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckpointSummaryDto } from '../models/CheckpointSummaryDto';
import type { EventSummaryDto } from '../models/EventSummaryDto';
import type { ParticipantSummaryDto } from '../models/ParticipantSummaryDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StatisticsService {

    /**
     * @returns EventSummaryDto Success
     * @throws ApiError
     */
    public static getApiStatisticsEventSummary(): CancelablePromise<EventSummaryDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/statistics/eventSummary',
        });
    }

    /**
     * @returns CheckpointSummaryDto Success
     * @throws ApiError
     */
    public static getApiStatisticsCheckpointSummary(): CancelablePromise<CheckpointSummaryDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/statistics/checkpointSummary',
        });
    }

    /**
     * @returns ParticipantSummaryDto Success
     * @throws ApiError
     */
    public static getApiStatisticsParticipantSummary(): CancelablePromise<Array<ParticipantSummaryDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/statistics/participantSummary',
        });
    }

}
