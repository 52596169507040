import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CheckpointSummaryDto, EventSummaryDto, StatisticsService } from 'src/api';
import type { AppThunk, RootState } from 'src/store/index';

import { fetchStart, fetchSuccess, fetchError } from 'src/store/slices/commonSlice';

export interface StatisticsState {
  eventSummary: EventSummaryDto | null;
  checkpointSummary: CheckpointSummaryDto | null;
}

const initialState: StatisticsState = {
  eventSummary: null,
  checkpointSummary: null,
};

export const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    setEventSummary: (state, action: PayloadAction<EventSummaryDto>) => {
      state.eventSummary = action.payload;
    },
    setCheckpointSummary: (state, action: PayloadAction<CheckpointSummaryDto>) => {
      state.checkpointSummary = action.payload;
    },
  },
});

export const { setEventSummary, setCheckpointSummary } = statisticsSlice.actions;

export const onGetEventSummary = (): AppThunk => async (dispatch) => {
  try {
    dispatch(fetchStart());
    const eventSummary = await StatisticsService.getApiStatisticsEventSummary();
    dispatch(setEventSummary(eventSummary));
    dispatch(fetchSuccess());
  } catch (e) {
    dispatch(fetchError((e as Error).message));
  }
};

export const onGetCheckpointSummary = (): AppThunk => async (dispatch) => {
  try {
    dispatch(fetchStart());
    const checkpointSummary = await StatisticsService.getApiStatisticsCheckpointSummary();
    dispatch(setCheckpointSummary(checkpointSummary));
    dispatch(fetchSuccess());
  } catch (e) {
    dispatch(fetchError((e as Error).message));
  }
};

export default statisticsSlice.reducer;

export const statisticsStateSelector = (state: RootState): StatisticsState => state.statistics;
