import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'src/store/index';

export interface MenuState {
  topmeny: string;
  page: string;
}

const initialState: MenuState = {
  topmeny: 'participant', // "participant" | "organizer"
  page: '',
};

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setTopMenu: (state, action: PayloadAction<string>) => {
      state.topmeny = action.payload;
    },
    setPage: (state, action: PayloadAction<string>) => {
      state.page = action.payload;
    },
  },
});

export const { setPage, setTopMenu } = menuSlice.actions;

export default menuSlice.reducer;

export const menuStateSelector = (state: RootState): MenuState => state.menu;
