import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from 'src/store/index';

import { fetchStart, fetchSuccess, fetchError } from 'src/store/slices/commonSlice';
import { UserDto, UsersService } from 'src/api';

export interface UserState {
  loading: boolean;
  hasError: boolean;
  error?: string;
  users: UserDto[];
  user: UserDto | null;
}

const initialState: UserState = {
  users: [],
  user: null,
  loading: false,
  hasError: false,
  error: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = `${action.payload}`;
    },
    setUsers: (state, action: PayloadAction<UserDto[]>) => {
      state.users = action.payload;
    },
    update: (state, action: PayloadAction<UserDto>) => {
      state.user = action.payload;
      state.users = state.users.map((user) =>
        user.id === action.payload.id ? action.payload : user
      );
    },
    saving: (state) => {
      state.hasError = false;
      state.error = '';
    },
  },
});

export const { loading, saving, hasError, update, setUsers } = userSlice.actions;

export const loadUsersAsync = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loading(true));
    const data = await UsersService.getApiUsers();
    dispatch(setUsers(data));
    dispatch(loading(false));
  } catch (e) {
    dispatch(hasError((e as Error).message));
  }
};

export const onUpdateUser =
  (user: UserDto): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchStart());

      dispatch(saving());
      const userResult = await UsersService.putApiUsers(user!.id!, user);

      dispatch(update(userResult));
      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(hasError((e as Error).message));
      dispatch(fetchError((e as Error).message));
    }
    return true;
  };

export const onRemoveUser =
  (eventId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchStart());
      const result = await UsersService.deleteApiUsers(eventId);

      dispatch(update(result));
      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError((e as Error).message));
    }
  };

export const userStateSelector = (state: RootState): UserState => state.user;

export default userSlice.reducer;
