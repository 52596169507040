import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from 'src/store/index';
import { fetchStart, fetchSuccess, fetchError } from 'src/store/slices/commonSlice';
import { MunicipalitiesService, MunicipalityDto } from 'src/api';

export interface MunicipalityState {
  municipality: MunicipalityDto[] | null;
}

const initialState: MunicipalityState = {
  municipality: [],
};

export const municipalitySlice = createSlice({
  name: 'municipality',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<MunicipalityDto[]>) => {
      state.municipality = action.payload;
    },
  },
});

export const { update } = municipalitySlice.actions;

export const onGetMunicipalities = (): AppThunk => async (dispatch) => {
  try {
    dispatch(fetchStart());
    const municipalities = await MunicipalitiesService.getApiMunicipalities();

    dispatch(update(municipalities));
    dispatch(fetchSuccess());
  } catch (e) {
    dispatch(fetchError((e as Error).message));
  }
};

export const onGetMunicipalitiesByRegion =
  (regionId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchStart());
      const municipalities = await MunicipalitiesService.getApiMunicipalities(regionId);
      dispatch(update(municipalities));
      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError((e as Error).message));
    }
  };

export default municipalitySlice.reducer;

export const municipalityStateSelector = (state: RootState): MunicipalityState =>
  state.municipality;
