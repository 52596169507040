/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EventDto } from '../models/EventDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ClubEventService {

    /**
     * @param clubId 
     * @returns EventDto Success
     * @throws ApiError
     */
    public static getApiClubevents(
clubId?: number,
): CancelablePromise<Array<EventDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/clubevents',
            query: {
                'clubId': clubId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns EventDto Success
     * @throws ApiError
     */
    public static postApiClubevents(
requestBody?: EventDto,
): CancelablePromise<EventDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/clubevents',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns EventDto Success
     * @throws ApiError
     */
    public static getApiClubevents1(
id: number,
): CancelablePromise<EventDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/clubevents/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static putApiClubevents(
id: number,
requestBody?: EventDto,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/clubevents/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiClubevents(
id: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/clubevents/{id}',
            path: {
                'id': id,
            },
        });
    }

}
