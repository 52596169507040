/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EntryCompetitorDto } from '../models/EntryCompetitorDto';
import type { EntryDto } from '../models/EntryDto';
import type { EventDto } from '../models/EventDto';
import type { EventEntryDto } from '../models/EventEntryDto';
import type { EventPriceDrawDto } from '../models/EventPriceDrawDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EventService {

    /**
     * @returns EventEntryDto Success
     * @throws ApiError
     */
    public static getApiEvents(): CancelablePromise<Array<EventEntryDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/events',
        });
    }

    /**
     * @param year 
     * @returns EventDto Success
     * @throws ApiError
     */
    public static getApiEventsByYear(
year?: number,
): CancelablePromise<Array<EventDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/events/byYear',
            query: {
                'year': year,
            },
        });
    }

    /**
     * @returns EventEntryDto Success
     * @throws ApiError
     */
    public static getApiEventsMy(): CancelablePromise<Array<EventEntryDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/events/my',
        });
    }

    /**
     * @param id 
     * @returns EventEntryDto Success
     * @throws ApiError
     */
    public static getApiEvents1(
id: number,
): CancelablePromise<EventEntryDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/events/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns EventEntryDto Success
     * @throws ApiError
     */
    public static getApiEventsEntry(
id: number,
): CancelablePromise<EventEntryDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/events/{id}/entry',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns EntryCompetitorDto Success
     * @throws ApiError
     */
    public static getApiEventsEntries(
id: number,
): CancelablePromise<Array<EntryCompetitorDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/events/{id}/entries',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns EntryDto Success
     * @throws ApiError
     */
    public static postApiEventsAddEntry(
id: number,
): CancelablePromise<EntryDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/events/{id}/addEntry',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiEventsRemoveEntry(
id: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/events/{id}/removeEntry',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns EventPriceDrawDto Success
     * @throws ApiError
     */
    public static getApiEventsPricedraws(
id: number,
): CancelablePromise<Array<EventPriceDrawDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/events/{id}/pricedraws',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns EventPriceDrawDto Success
     * @throws ApiError
     */
    public static postApiEventsPricedraw(
requestBody?: EventPriceDrawDto,
): CancelablePromise<EventPriceDrawDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/events/pricedraw',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
