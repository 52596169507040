import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SortOrder } from 'src/utils/constants';
import type { RootState } from 'src/store/index';

export enum VIEW_TYPE {
  LIST = 0,
  MAP = 1,
}

export interface EventFilterState {
  viewType: VIEW_TYPE;
  searchString: string;
  region: number;
  page: number;
  pageSize: number;
  eventType: number;
  order: SortOrder;
  orderBy: string;
}

const initialState: EventFilterState = {
  viewType: VIEW_TYPE.MAP,
  searchString: '',
  region: 0,
  page: 0,
  pageSize: 10,
  eventType: 99,
  order: 'asc',
  orderBy: 'name',
};

export const eventFilterSlice = createSlice({
  name: 'eventFilter',
  initialState,
  reducers: {
    setViewType: (state, action: PayloadAction<VIEW_TYPE>) => {
      state.viewType = action.payload;
    },
    setSearchString: (state, action: PayloadAction<string>) => {
      state.searchString = action.payload;
    },
    setRegion: (state, action: PayloadAction<number>) => {
      state.region = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setEventType: (state, action: PayloadAction<number>) => {
      state.eventType = action.payload;
    },
    setOrder: (state, action: PayloadAction<SortOrder>) => {
      state.order = action.payload;
    },
    setOrderBy: (state, action: PayloadAction<string>) => {
      state.orderBy = action.payload;
    },
  },
});

export const {
  setViewType,
  setSearchString,
  setRegion,
  setPage,
  setPageSize,
  setEventType,
  setOrder,
  setOrderBy,
} = eventFilterSlice.actions;

export default eventFilterSlice.reducer;

export const eventFilterStateSelector = (state: RootState): EventFilterState => state.eventFilter;
