import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SortOrder } from 'src/utils/constants';
import type { RootState } from 'src/store/index';

export interface ClubEventFilterState {
  page: number;
  pageSize: number;
  order: SortOrder;
  orderBy: string;
}

const initialState: ClubEventFilterState = {
  page: 0,
  pageSize: 10,
  order: 'desc',
  orderBy: 'startDate',
};

export const clubEventFilterSlice = createSlice({
  name: 'clubEventFilter',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setOrder: (state, action: PayloadAction<SortOrder>) => {
      state.order = action.payload;
    },
    setOrderBy: (state, action: PayloadAction<string>) => {
      state.orderBy = action.payload;
    },
  },
});

export const { setPage, setOrder, setOrderBy } = clubEventFilterSlice.actions;

export default clubEventFilterSlice.reducer;

export const clubEventFilterStateSelector = (state: RootState): ClubEventFilterState =>
  state.clubEventFilter;
