import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClubBaseDto } from 'src/api';
import type { RootState } from 'src/store';

export interface SettingState {
  navCollapsed: boolean;
  initialPath: string | null;
  pageHeader: React.ReactElement | null;
  backgroundColor: string | undefined;
  backgroundImage: string | undefined;
  currentClub: ClubBaseDto | null;
}

const initialState: SettingState = {
  navCollapsed: false,
  initialPath: '/',
  pageHeader: null,
  backgroundColor: '',
  backgroundImage: '',
  currentClub: null,
};

export const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    routeChange: (state) => {
      state.navCollapsed = false;
    },
    toggleNavCollapsed: (state) => {
      state.navCollapsed = !state.navCollapsed;
    },
    setInitialPath: (state, action: PayloadAction<string | null>) => {
      state.initialPath = action.payload;
    },
    onNavCollapsed: (state) => {
      state.navCollapsed = false;
    },
    setPageHeader: (state, action: PayloadAction<React.ReactElement | null>) => {
      state.pageHeader = action.payload;
    },
    setCurrentClub: (state, action: PayloadAction<ClubBaseDto | null>) => {
      state.currentClub = action.payload;
    },
  },
});

export const {
  routeChange,
  toggleNavCollapsed,
  setInitialPath,
  onNavCollapsed,
  setPageHeader,
  setCurrentClub,
} = settingSlice.actions;

export default settingSlice.reducer;

export const settingStateSelector = (state: RootState): SettingState => state.setting;
