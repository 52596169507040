import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from 'src/store/index';
import { fetchStart, fetchSuccess, fetchError } from 'src/store/slices/commonSlice';
import { RegionDto, RegionService } from 'src/api';

export interface RegionState {
  regions: RegionDto[] | null;
}

const initialState: RegionState = {
  regions: [],
};

export const regionSlice = createSlice({
  name: 'region',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<RegionDto[]>) => {
      state.regions = action.payload;
    },
  },
});

export const { update } = regionSlice.actions;

export const onGetRegions = (): AppThunk => async (dispatch) => {
  try {
    dispatch(fetchStart());
    const regions = await RegionService.getApiRegions();

    dispatch(update(regions));
    dispatch(fetchSuccess());
  } catch (e) {
    dispatch(fetchError((e as Error).message));
  }
};

export default regionSlice.reducer;

export const regionStateSelector = (state: RootState): RegionState => state.region;
