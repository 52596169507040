// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      new: `${ROOTS.DASHBOARD}/user/new`,
      list: `${ROOTS.DASHBOARD}/user/list`,
      cards: `${ROOTS.DASHBOARD}/user/cards`,
      account: (id: string) => `${ROOTS.DASHBOARD}/user/${id}/account`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
    },
    event: {
      root: `${ROOTS.DASHBOARD}/event`,
      new: `${ROOTS.DASHBOARD}/event/new`,
      list: `${ROOTS.DASHBOARD}/event/list`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/event/${id}/edit`,
    },
    club: {
      root: `${ROOTS.DASHBOARD}/club`,
      new: `${ROOTS.DASHBOARD}/club/new`,
      list: `${ROOTS.DASHBOARD}/club/list`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/club/${id}/edit`,
    },
  },
};
