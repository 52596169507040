import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from 'src/store/index';
import { fetchStart, fetchSuccess, fetchError } from 'src/store/slices/commonSlice';
import { EventDto, EventEntryDto, EventService } from 'src/api';

export interface EventState {
  events: EventDto[];
}

const initialState: EventState = {
  events: [],
};

export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setList: (state, action: PayloadAction<EventEntryDto[]>) => {
      state.events = action.payload;
    },

    reset: (state) => {
      state.events = [];
    },
  },
});

export const { setList, reset } = eventSlice.actions;

export const onGetEvents =
  (year: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchStart());
      const events = await EventService.getApiEventsByYear(year);

      dispatch(setList(events));
      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError((e as Error).message));
    }
  };

export default eventSlice.reducer;

export const eventStateSelector = (state: RootState): EventState => state.event;
