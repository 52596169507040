import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from 'src/store/index';
import { fetchStart, fetchSuccess, fetchError } from 'src/store/slices/commonSlice';
import { AdminService, ClubDto, ClubsService } from 'src/api';

export interface ClubState {
  loading: boolean;
  hasError: boolean;
  error?: string;
  clubs: ClubDto[] | null;
}

const initialState: ClubState = {
  loading: false,
  hasError: false,
  error: '',
  clubs: [],
};

export const clubSlice = createSlice({
  name: 'club',
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = `${action.payload}`;
    },
    saving: (state) => {
      state.hasError = false;
      state.error = '';
    },
    update: (state, action: PayloadAction<ClubDto[]>) => {
      state.clubs = action.payload;
    },
  },
});

export const { loading, saving, hasError, update } = clubSlice.actions;

export const loadClubsAsync = (): AppThunk => async (dispatch) => {
  try {
    dispatch(fetchStart());
    const clubs = await ClubsService.getApiClubs();

    dispatch(update(clubs));
    dispatch(fetchSuccess());
  } catch (e) {
    dispatch(fetchError((e as Error).message));
  }
};

export const onSyncWithEventor = (): AppThunk => async (dispatch) => {
  try {
    const result = await AdminService.postApiAdminSyncClubs();
    if (result) {
      const clubs = await ClubsService.getApiClubs();
      dispatch(update(clubs));
    }

    return true;
  } catch (e) {
    dispatch(hasError((e as Error).message));
    dispatch(fetchError((e as Error).message));
    return false;
  }
};

export default clubSlice.reducer;

export const clubStateSelector = (state: RootState): ClubState => state.club;
