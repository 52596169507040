import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EntryCompetitorDto, EventService } from 'src/api';
import type { AppThunk, RootState } from 'src/store/index';
import { fetchStart, fetchSuccess, fetchError } from 'src/store/slices/commonSlice';

export interface EntryState {
  entries: EntryCompetitorDto[] | null;
}

const initialState: EntryState = {
  entries: [],
};

export const entrySlice = createSlice({
  name: 'entry',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<EntryCompetitorDto[]>) => {
      state.entries = action.payload;
    },
  },
});

export const { update } = entrySlice.actions;

export const onGetEventEntries =
  (id: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchStart());
      const entries = await EventService.getApiEventsEntries(id);

      dispatch(update(entries));
      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError((e as Error).message));
    }
  };

export default entrySlice.reducer;

export const entryStateSelector = (state: RootState): EntryState => state.entry;
