import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from 'src/store/index';
import { fetchStart, fetchSuccess, fetchError, showMessage } from 'src/store/slices/commonSlice';
import {
  ClubEventService,
  EventDto,
  MunicipalitiesService,
  MunicipalityDto,
  RegionDto,
  RegionService,
} from 'src/api';

export interface EventState {
  selectedEvent: EventDto | null;
  events: EventDto[];
  regions: RegionDto[] | null;
  municipalities: MunicipalityDto[] | null;
}

const initialState: EventState = {
  selectedEvent: null,
  events: [],
  regions: [],
  municipalities: [],
};

export const clubEventSlice = createSlice({
  name: 'clubEvent',
  initialState,
  reducers: {
    setList: (state, action: PayloadAction<EventDto[]>) => {
      state.events = action.payload;
    },
    updateEvent: (state, action: PayloadAction<EventDto>) => {
      state.events = state.events.map((event) =>
        event.id === action.payload.id ? action.payload : event
      );
      state.selectedEvent = action.payload;
    },
    addEvent: (state, action: PayloadAction<EventDto>) => {
      state.events.push(action.payload);
    },
    setSelectedEvent: (state, action: PayloadAction<EventDto>) => {
      state.selectedEvent = action.payload;
    },
    setRegions: (state, action: PayloadAction<RegionDto[]>) => {
      state.regions = action.payload;
    },
    setMunicipalities: (state, action: PayloadAction<MunicipalityDto[]>) => {
      state.municipalities = action.payload;
    },
    setNewSelectedEvent: (state) => {
      state.selectedEvent = null;
      state.regions = [];
      state.municipalities = [];
    },
  },
});

export const {
  setList,
  updateEvent,
  addEvent,
  setSelectedEvent,
  setRegions,
  setMunicipalities,
  setNewSelectedEvent,
} = clubEventSlice.actions;

export const onGetClubEvents =
  (clubId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchStart());
      dispatch(setList([]));
      const events = await ClubEventService.getApiClubevents(clubId);

      dispatch(setList(events));
      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError((e as Error).message));
    }
  };

export const onGetEvent =
  (eventId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchStart());
      const event = await ClubEventService.getApiClubevents1(eventId);

      dispatch(setSelectedEvent(event));
      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError((e as Error).message));
    }
  };

export const onCreateEvent =
  (event: EventDto): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchStart());
      const newEvent = await ClubEventService.postApiClubevents(event);

      dispatch(setSelectedEvent(newEvent));
      dispatch(showMessage('message.eventCreated'));
    } catch (e) {
      dispatch(fetchError((e as Error).message));
    }
  };

export const onUpdateEvent =
  (event: EventDto): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchStart());
      const updatedEvent = await ClubEventService.putApiClubevents(event.id!, event);

      dispatch(updateEvent(updatedEvent));
      dispatch(showMessage('message.eventUpdated'));
    } catch (e) {
      dispatch(fetchError((e as Error).message));
    }
  };

export const onGetRegions = (): AppThunk => async (dispatch) => {
  try {
    dispatch(fetchStart());
    const regions = await RegionService.getApiRegions();

    dispatch(setRegions(regions));
    dispatch(setMunicipalities([]));
    dispatch(fetchSuccess());
  } catch (e) {
    dispatch(fetchError((e as Error).message));
  }
};

export const onGetMunicipalitiesByRegion =
  (regionId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchStart());
      const municipalities = await MunicipalitiesService.getApiMunicipalities(regionId);
      dispatch(setMunicipalities(municipalities));
      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError((e as Error).message));
    }
  };

export default clubEventSlice.reducer;

export const clubEventStateSelector = (state: RootState): EventState => state.clubEvent;
