import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();

    const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    // OR using local (public folder)
    // -------------------------------------------------------
    // const logo = (
    //   <Box
    //     component="img"
    //     src="/logo/logo_single.svg" => your path
    //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    //   />
    // );

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: 121,
          height: 54,
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 121 54">
          <defs>
            <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
              <stop offset="0%" stopColor={PRIMARY_DARK} />
              <stop offset="100%" stopColor={PRIMARY_MAIN} />
            </linearGradient>

            <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
              <stop offset="0%" stopColor={PRIMARY_LIGHT} />
              <stop offset="100%" stopColor={PRIMARY_MAIN} />
            </linearGradient>

            <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
              <stop offset="0%" stopColor={PRIMARY_LIGHT} />
              <stop offset="100%" stopColor={PRIMARY_MAIN} />
            </linearGradient>
          </defs>

          <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
            <path
              fill="url(#BG1)"
              d="M44.7,47.3c-0.7,0.8-1.7,1.2-3,1.2s-2.3-0.4-3-1.2c-0.7-0.8-1-1.8-1-2.9c0-1.1,0.3-2.1,1-2.9c0.7-0.8,1.7-1.2,3-1.2
			s2.3,0.4,3,1.2c0.7,0.8,1,1.8,1,2.9C45.7,45.5,45.4,46.5,44.7,47.3z M43.1,46.2c0.3-0.4,0.5-1,0.5-1.8c0-0.8-0.2-1.4-0.5-1.8
			c-0.3-0.4-0.8-0.6-1.4-0.6c-0.6,0-1.1,0.2-1.4,0.6c-0.3,0.4-0.5,1-0.5,1.8c0,0.8,0.2,1.4,0.5,1.8c0.3,0.4,0.8,0.6,1.4,0.6
			C42.3,46.8,42.8,46.6,43.1,46.2z"
            />
            <path
              fill="url(#BG1)"
              d="M51.5,40.3c0,0,0.1,0,0.2,0v2.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.2,0c-0.8,0-1.4,0.3-1.7,0.8c-0.2,0.3-0.2,0.8-0.2,1.4
			v3.7h-2.1v-7.8h2v1.4c0.3-0.5,0.6-0.9,0.8-1.1C50.3,40.4,50.8,40.3,51.5,40.3C51.5,40.3,51.5,40.3,51.5,40.3z"
            />
            <path fill="url(#BG1)" d="M55,39.5h-2.1v-1.9H55V39.5z M52.9,40.5H55v7.8h-2.1V40.5z" />
            <path
              fill="url(#BG1)"
              d="M62.1,40.6c0.5,0.2,1,0.6,1.4,1.2c0.3,0.5,0.5,1,0.6,1.6c0.1,0.4,0.1,0.9,0.1,1.6h-5.7c0,0.8,0.3,1.3,0.8,1.7
			c0.3,0.2,0.7,0.3,1.1,0.3c0.5,0,0.8-0.1,1.1-0.4c0.2-0.1,0.3-0.3,0.4-0.5H64c-0.1,0.5-0.3,0.9-0.8,1.4c-0.7,0.8-1.7,1.1-2.9,1.1
			c-1,0-2-0.3-2.8-1c-0.8-0.6-1.2-1.7-1.2-3.1c0-1.4,0.4-2.4,1.1-3.1c0.7-0.7,1.7-1.1,2.8-1.1C60.9,40.3,61.5,40.4,62.1,40.6z
			 M59,42.4c-0.3,0.3-0.5,0.7-0.5,1.2H62c0-0.5-0.2-1-0.5-1.2c-0.3-0.3-0.7-0.4-1.2-0.4C59.7,41.9,59.3,42.1,59,42.4z"
            />
            <path
              fill="url(#BG1)"
              d="M71.5,40.9c0.5,0.4,0.8,1.1,0.8,2.1v5.3h-2.1v-4.8c0-0.4-0.1-0.7-0.2-0.9c-0.2-0.4-0.6-0.6-1.1-0.6
			c-0.7,0-1.2,0.3-1.4,0.9c-0.1,0.3-0.2,0.7-0.2,1.2v4.2h-2v-7.8h2v1.1c0.3-0.4,0.5-0.7,0.7-0.9c0.4-0.3,1-0.5,1.6-0.5
			C70.3,40.3,71,40.5,71.5,40.9z"
            />
            <path
              d="M73.3,42v-1.5h1.1v-2.2h2v2.2h1.3V42h-1.3v4.1c0,0.3,0,0.5,0.1,0.6c0.1,0.1,0.3,0.1,0.7,0.1c0.1,0,0.1,0,0.2,0
			c0.1,0,0.1,0,0.2,0v1.5l-1,0c-1,0-1.6-0.1-2-0.5c-0.2-0.2-0.3-0.6-0.3-1.1V42H73.3z"
            />
            <path
              fill="url(#BG1)"
              d="M84.2,40.6c0.5,0.2,1,0.6,1.4,1.2c0.3,0.5,0.5,1,0.6,1.6c0.1,0.4,0.1,0.9,0.1,1.6h-5.7c0,0.8,0.3,1.3,0.8,1.7
			c0.3,0.2,0.7,0.3,1.1,0.3c0.5,0,0.8-0.1,1.1-0.4c0.2-0.1,0.3-0.3,0.4-0.5h2.1c-0.1,0.5-0.3,0.9-0.8,1.4c-0.7,0.8-1.7,1.1-2.9,1.1
			c-1,0-2-0.3-2.8-1c-0.8-0.6-1.2-1.7-1.2-3.1c0-1.4,0.4-2.4,1.1-3.1c0.7-0.7,1.7-1.1,2.8-1.1C83,40.3,83.6,40.4,84.2,40.6z
			 M81.1,42.4c-0.3,0.3-0.5,0.7-0.5,1.2h3.5c0-0.5-0.2-1-0.5-1.2c-0.3-0.3-0.7-0.4-1.2-0.4C81.8,41.9,81.4,42.1,81.1,42.4z"
            />
            <path
              fill="url(#BG1)"
              d="M91.6,40.3c0,0,0.1,0,0.2,0v2.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.2,0c-0.8,0-1.4,0.3-1.7,0.8c-0.2,0.3-0.2,0.8-0.2,1.4
			v3.7h-2.1v-7.8h2v1.4c0.3-0.5,0.6-0.9,0.8-1.1C90.4,40.4,90.9,40.3,91.6,40.3C91.5,40.3,91.6,40.3,91.6,40.3z"
            />
            <path fill="url(#BG1)" d="M95.1,39.5H93v-1.9h2.1V39.5z M93,40.5h2.1v7.8H93V40.5z" />
            <path
              fill="url(#BG1)"
              d="M103.4,40.9c0.5,0.4,0.8,1.1,0.8,2.1v5.3H102v-4.8c0-0.4-0.1-0.7-0.2-0.9c-0.2-0.4-0.6-0.6-1.1-0.6
			c-0.7,0-1.2,0.3-1.4,0.9c-0.1,0.3-0.2,0.7-0.2,1.2v4.2h-2v-7.8h2v1.1c0.3-0.4,0.5-0.7,0.7-0.9c0.4-0.3,1-0.5,1.6-0.5
			C102.2,40.3,102.8,40.5,103.4,40.9z"
            />
            <path
              fill="url(#BG1)"
              d="M109.9,40.5c0.5,0.2,0.9,0.6,1.2,1.1v-1.1h2v7.4c0,1-0.2,1.8-0.5,2.3c-0.6,0.9-1.7,1.3-3.4,1.3c-1,0-1.8-0.2-2.4-0.6
			s-1-1-1-1.8h2.2c0.1,0.2,0.2,0.4,0.3,0.5c0.2,0.2,0.6,0.3,1.1,0.3c0.7,0,1.2-0.2,1.5-0.7c0.2-0.3,0.2-0.8,0.2-1.6v-0.5
			c-0.2,0.3-0.4,0.6-0.6,0.8c-0.4,0.3-0.9,0.5-1.6,0.5c-1,0-1.8-0.4-2.4-1.1s-0.9-1.7-0.9-2.9c0-1.2,0.3-2.1,0.9-2.9
			c0.6-0.8,1.4-1.2,2.5-1.2C109.3,40.3,109.6,40.3,109.9,40.5z M110.6,46.1c0.3-0.4,0.5-0.9,0.5-1.7c0-0.7-0.2-1.3-0.5-1.7
			c-0.3-0.4-0.7-0.6-1.3-0.6c-0.7,0-1.2,0.3-1.5,1c-0.1,0.4-0.2,0.8-0.2,1.3c0,0.5,0.1,0.9,0.2,1.2c0.3,0.7,0.8,1,1.5,1
			C109.9,46.6,110.3,46.4,110.6,46.1z"
            />
          </g>
          <g>
            <g>
              <polygon points="32.6,30.4 32.6,56.1 7,56.1 			" />
            </g>
          </g>
        </svg>
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
